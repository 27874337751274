import React from 'react';

/**
 * width 和 height 只需要无单位字符串就可以
 * color 颜色字符串
 */
export default ({ width, height, color = 'rgba(235, 0, 41, 1)' }) => {
  return (
    <svg
      width={width}
      height={height}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='0'
        x2={width}
        y1='0'
        y2={height}
        stroke={color}
        strokeWidth='2'
      />
    </svg>
  );
};
