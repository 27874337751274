import React from 'react';
import { useHistory } from 'react-router-dom';

const Navi = () => {
  const history = useHistory();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          height: '80px',
          justifyContent: 'center',
          boxShadow: '0px 4px 8px 0px rgba(192, 196, 204, 0.4)',
        }}
      >
        <div style={{ width: 1200, height: 80 }}>
          <img
            src={require('../assets/logo.png')}
            alt=''
            onClick={() => {
              history.push('/');
            }}
            style={{ cursor: 'pointer', width: '293px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Navi;
