import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTitle } from 'ahooks';

/**
 * 每个具有嵌套层级的页面都需要这个组件;
 * 自动向子组件注入下一层级的 routes;
 * 新增：根据路由的 name || path 属性自动更改网站 title
 */
const Switcher = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} /> // TODO: 这个地方不能用 route={route} ？？？
      ))}
    </Switch>
  );
};

function RouteWithSubRoutes(route) {
  const nextTitle = route.name || route.path;
  useTitle(nextTitle); // 设置网站标题
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        // 向下传递，保持嵌套层级
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default Switcher;
