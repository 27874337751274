import React, { useState, useEffect } from 'react';
import Slider from './Slider';
import Grow from '../../components/Grow';
import { Button } from 'antd';
import { getPosts } from '../../mock/posts';
import PostItem from './PostItem';
import LineSVG from '../../components/LineSVG';
import { useHistory } from 'react-router-dom';

const Home = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getPosts().then((res) => setData(res.data));
  }, []);
  const history = useHistory();

  return (
    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
      <Grow y='40px' />
      <Slider />

      <Grow y='60px' />
      <span style={{ fontSize: '40px', fontWeight: '500' }}>商家入驻流程</span>
      <Grow y='20px' />
      <LineSVG width='100' height='1' />
      <img
        src={require('../../assets/入驻流程.png')}
        alt='入驻流程'
        style={{ width: '980px', height: '155px', margin: '60px' }}
      />
      <div
        style={{
          alignSelf: 'stretch',
          background: '#F5F5F6',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Grow y='60px' />
        <span style={{ fontSize: '40px', fontWeight: '500' }}>入驻标准</span>
        <Grow y='20px' />
        <LineSVG width='100' height='1' />
        <Grow y='40px' />

        <img
          src={require('../../assets/公司资质.png')}
          alt='公司资质'
          style={{ width: '1120px' }}
        />
        <Grow y='40px' />
        <img
          src={require('../../assets/品牌资质.png')}
          alt='品牌资质'
          style={{ width: '1120px' }}
        />
        <Button
          type='primary'
          style={{
            width: '240px',
            height: '48px',
            fontSize: '20px',
            fontWeight: '500',
            margin: '60px',
            borderRadius: '2px',
          }}
          onClick={() => {
            history.push('/join/form');
            window.scrollTo(0, 0); // 滚动问题
          }}
        >
          立即入驻
        </Button>
      </div>

      <div style={{ padding: '60px 0' }}>
        {data.map((v, i) => {
          return (
            <PostItem {...v} line={i !== data.length - 1} key={i}></PostItem>
          );
        })}
      </div>
      <Grow y='20px' />

      <div
        style={{
          alignSelf: 'stretch',
          background: '#F5F5F6',
          height: '160px',
          padding: '70px 0',
          textAlign: 'center',
        }}
      >
        <span style={{ fontSize: '14px', color: '#999999' }}>
          © 2020 Nongfu Spring. All rights reserved.
          <a href='https://beian.miit.gov.cn'>浙ICP备2020034251号-1</a>
        </span>
      </div>
    </div>
  );
};

export default Home;
