import React, { useState, useMemo } from 'react';
import { Modal } from 'antd';
import useQRCode from '../../hooks/useQRCode';
/**
 * url 注意要有协议
 */
export default ({ url = 'http://www.baidu.com', qr = true }) => {
  const [visible, setVisible] = useState(false);
  const handleButtonBoxClick = useMemo(() => {
    return qr ? () => setVisible(true) : () => window.open(url, '_blank');
  }, [qr, url]);
  const { ImgDOM } = useQRCode(url, { needImgDOM: true });

  const buttonBox = useMemo(() => {
    return (
      <div
        style={{
          position: 'absolute',
          left: '136px',
          bottom: '22px',
          height: '40px',
          width: '112px',
          cursor: 'pointer',
        }}
        onClick={handleButtonBoxClick}
      />
    );
  }, [handleButtonBoxClick]);

  const content = useMemo(() => {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 500,
            color: '#333333',
            margin: '64px 0 48px',
            height: '28px',
            lineHeight: '28px',
          }}
        >
          请用微信扫码选购
        </p>
        {ImgDOM}
      </div>
    );
  }, [ImgDOM]);

  return qr ? (
    <>
      <Modal
        visible={visible}
        bodyStyle={{ width: 280, height: 320, margin: 0, padding: 0 }}
        footer={false}
        width='280px'
        onCancel={() => setVisible(false)}
      >
        {content}
      </Modal>
      {buttonBox}
    </>
  ) : (
    <div>{buttonBox}</div>
  );
};
