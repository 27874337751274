import React from 'react';
import styles from './Join.module.scss';
import Grow from '../../components/Grow';
import Switcher from '../../router/Switcher';
export default ({ routes }) => {
  return (
    <div>
      <div className={styles['title-container']}>
        <span className={styles['title-txt']}>注册企业用户</span>
      </div>
      <Grow y='64px' />
      <Switcher routes={routes} />
    </div>
  );
};
