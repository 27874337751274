import { message } from 'antd';

// 需要判断 data是否为 null
export const getPost = ({ skuId, mobile }) => {
  return fetch(`${window.location.origin}/zmall/trade/v1/edi/orders:query`, {
    method: 'post',
    body: JSON.stringify({ skuId, mobile }),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((res) => {
      !res.success && message.error(res.message);
      return res;
    })
    .catch();
};
