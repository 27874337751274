import React from 'react';

export default () => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 200px)',
      }}
    >
      <h1>404 Not Found</h1>
    </div>
  );
};
