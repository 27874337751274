/**
 * data: [{title, desc, img}]
 */
export const getPosts = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            title: '凌晨一点，那些不回家的人都在干什么？',
            desc:
              '夜色已深，整个城市渐渐睡去。 热闹的社区早已褪去喧闹的色彩，这里的一切归于宁静平和。 灯光依旧的芝麻店，带来24小时不打烊的陪伴。 我们开始好奇那些深夜的芝麻店故事。',
            img: require('../assets/文章一配图.png'),
            skuId: '2020092900839',
            url:
              'https://vem-mobile.nfsq.com.cn/zmall-mobile/auth?redirect=goods-detail&commodityId=20200929000000031&commodityType=1',
          },
          {
            title: '你一定没想过，第一次下厨就能做出这碗神仙鱼汤!',
            desc:
              '美食是不同食材组合交织的美妙产物，化自然滋味于无形，后留有余香，美妙至极。 新鲜的食材，甘甜的水，是对美食最好的致敬。 农夫山泉在全国拥有八大水源地，每个水源地景色各有千秋，个个美得不像话。 用这一方源水做出的美食会有怎样的魔力？',
            img: require('../assets/文章二配图.png'),
            skuId: '2020092900342',
            url:
              'https://vem-mobile.nfsq.com.cn/zmall-mobile/auth?redirect=goods-detail&commodityId=20200929000000021&commodityType=1',
          },
        ],
      });
    }, 0);
  });
};
