import Home from "../pages/Home";
import Join from "../pages/join";
import Form from "../pages/join/Form";
import OK from "../pages/join/OK";
import NotFound from "../pages/404";

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    name: "农夫山泉（安吉）智能生活",
  },
  {
    path: "/join",
    component: Join,
    name: "注册企业用户",
    routes: [
      {
        path: "/join/form",
        component: Form,
      },
      {
        path: "/join/ok",
        component: OK,
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
    name: "Not Found",
  },
];
export default routes;
