import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import Navi from './components/Navi';

import routes from './router';
import Switcher from './router/Switcher';

function App() {
  return (
    <div className='App'>
      <Navi />
      <Switcher routes={routes} />
    </div>
  );
}

export default App;
