import React from 'react';
import Grow from '../../components/Grow';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: '280px',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <img src={require('../../assets/ok@2x.png')} alt='ok' />

        <div
          style={{
            width: '128px',
            height: '32px',
            margin: '24px 72px 0 80px',
            lineHeight: '32px',
            fontSize: '24px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          提交成功！
        </div>
        <Grow y='40px' />
        <Button
          type='primary'
          style={{ height: '40px', width: '120px', borderRadius: '2px' }}
          onClick={() => history.push('/')}
        >
          返回首页
        </Button>
        <Grow y='288px' />
      </div>
    </div>
  );
};
