import React from 'react';
import { useBoolean } from 'ahooks';
import { Form, Input, Button, Checkbox, Col, Row } from 'antd';
import CommentsAfterInput from './CommentsAfterInput';
import { useHistory } from 'react-router-dom';
import Grow from '../../components/Grow';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

export default () => {
  const [loading, { toggle: toggleLoading }] = useBoolean(false);

  // 下面是自定义表单验证
  const validateUserName = (_, value) => {
    // 支持中文、英文、数字，6-25个字符内。
    const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{6,25}$/;
    if (reg.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject('用户名不符合要求');
    }
  };
  const validatePassword = (_, value) => {
    // 至少8个字符，需包含字母、数字、特殊字符（特殊字符包括：!@#$%&*_）
    const reg = /^\S*(?=\S{8,})(?=\S*\d)(?=\S*[a-zA-Z])(?=\S*[!@#$%&*_])\S*$/;
    if (reg.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject('密码不符合要求');
    }
  };
  const validatePasswordRepeat = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject('两次密码输入不一致');
    },
  });
  const validateCheckbox = (_, value) => {
    return value ? Promise.resolve() : Promise.reject('请阅读商户入住服务协议');
  };

  const history = useHistory();
  const handleFinish = (values) => {
    console.log(values);
    toggleLoading(); // 切换 loading
    new Promise((resolve, reject) => {
      setTimeout(() => {
        toggleLoading();
        history.push('/join/ok');
      }, 1000);
    });
  };
  return (
    <div style={{ maxWidth: 1400, margin: '0 auto' }}>
      <Form {...layout} onFinish={handleFinish}>
        <Form.Item label='用户名' required>
          <Row>
            <Col span={8}>
              <Form.Item
                name='userName'
                rules={[
                  { required: true, message: '请输入用户名' },
                  { validator: validateUserName },
                ]}
                noStyle
              >
                <Input placeholder='请输入' />
              </Form.Item>
            </Col>
            <CommentsAfterInput content='支持中文、英文、数字，6-25个字符内。' />
          </Row>
        </Form.Item>
        <Form.Item label='设置密码' required>
          <Row>
            <Col span={8}>
              <Form.Item
                name='password'
                rules={[
                  { required: true, message: '请输入密码' },
                  { validator: validatePassword },
                ]}
                noStyle
              >
                <Input />
              </Form.Item>
            </Col>
            <CommentsAfterInput content='至少8个字符，需包含字母、数字、特殊字符（特殊字符包括：!@#$%&*_）' />
          </Row>
        </Form.Item>
        <Form.Item label='确认密码' required>
          <Row>
            <Col span={8}>
              <Form.Item
                name='passwordRepeat'
                rules={[
                  { required: true, message: '请输入确认密码' },
                  // 这里是 非RuleConfig 的形式
                  validatePasswordRepeat,
                ]}
                noStyle
              >
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label='企业邮箱' required>
          <Row>
            <Col span={8}>
              <Form.Item
                name='email'
                rules={[
                  { type: 'email', required: true, message: '请输入企业邮箱' },
                ]}
                noStyle
              >
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label='公司名称' required>
          <Row>
            <Col span={8}>
              <Form.Item
                name='companyName'
                rules={[{ required: true, message: '请输入公司名称' }]}
                noStyle
              >
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <CommentsAfterInput content='营业执照上公司名称。' />
          </Row>
        </Form.Item>

        <Form.Item
          name='agreement'
          {...tailLayout}
          rules={[{ validator: validateCheckbox }]}
          valuePropName='checked'
        >
          <Checkbox>
            <a
              style={{ textDecoration: 'underline' }}
              href='/商家入驻服务协议.html'
            >
              商家入驻服务协议
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            htmlType={'submit'}
            type={'primary'}
            style={{
              width: '120px',
              height: '40px',
              fontSize: '16px',
            }}
            loading={loading}
          >
            提交注册
          </Button>
        </Form.Item>
      </Form>
      <Grow y='214px' />
    </div>
  );
};
