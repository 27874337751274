import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import LineSVG from '../../components/LineSVG';
import Grow from '../../components/Grow';
import * as api from './api';
import useQRCode from '../../hooks/useQRCode';

export default ({
  title,
  desc,
  img: image,
  line,
  url = 'http://www.baidu.com',
  skuId,
}) => {
  const { ImgDOM } = useQRCode(url, { needImgDOM: true });

  const [modal1Visible, setModal1Visible] = useState(false);
  const handleModal1OK = () => {
    setModal2Visible(true);
    setModal1Visible(false);
  };
  const handleModal1Cancel = () => {
    setModal1Visible(false);
  };

  const [modal2Visible, setModal2Visible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [displayValue, setDisplayValue] = useState('');
  const [loading, setLoading] = useState(false);
  const handleModal2OK = () => {
    // 表单校验
    const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
    if (!reg.test(inputValue)) {
      setDisplayValue('请输入手机号!');
      return;
    }
    // 查询
    setLoading(true);
    const promise = api.getPost({
      skuId: skuId,
      mobile: inputValue,
    });
    promise
      .then((res) => {
        if (res.success && res.data) {
          setDisplayValue('');
          window.open(res.data, '_blank');
          setLoading(false);
          setModal2Visible(false);
        } else {
          setDisplayValue('未查询到该手机号相关订单！');
          setLoading(false);
        }
      })
      .catch((_) => setLoading(false));
  };
  const handleModal2Cancel = () => {
    setModal2Visible(false);
    setLoading(false);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            height: '200px',
            flexFlow: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 500, color: '#333333' }}>
            {title}
          </div>

          <div>
            {desc.split('。').map((v, i) => (
              <div
                style={{
                  color: '#666666',
                  lineHeight: '22px',
                }}
                key={i}
              >
                {v}
              </div>
            ))}
          </div>

          <Button
            ghost
            type='primary'
            style={{ width: '180px', height: '40px' }}
            onClick={() => {
              setModal1Visible(true);
            }}
          >
            付费阅读
          </Button>
          <Modal
            visible={modal1Visible}
            bodyStyle={{ width: 360, height: 320, margin: 0, padding: 0 }}
            footer={false}
            width='360px'
            closable={false}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '288px',
              }}
            >
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 500,
                  color: '#333333',
                  marginTop: 40,
                  height: '28px',
                  lineHeight: '28px',
                }}
              >
                该文章为付费阅读，请扫码支付
              </div>
              {ImgDOM}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '40px',
                }}
              >
                <Button
                  onClick={handleModal1Cancel}
                  style={{ width: 120, height: 40, marginRight: 16 }}
                  ghost
                  type='primary'
                >
                  取消
                </Button>
                <Button
                  onClick={handleModal1OK}
                  style={{ width: 120, height: 40 }}
                  type='primary'
                >
                  我已支付
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            visible={modal2Visible}
            bodyStyle={{ width: 360, height: 320, margin: 0, padding: 0 }}
            footer={false}
            width='360px'
            closable={false}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 500,
                  color: '#333333',
                  margin: '40px 0 48px',
                  height: '28px',
                  lineHeight: '28px',
                }}
              >
                请输入订单手机号
              </div>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder='请输入'
                style={{ width: '256px' }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#F58003',
                  margin: '8px 0 64px',
                  width: '256px',
                  height: '20px',
                  lineHeight: '20px',
                }}
              >
                {displayValue}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={handleModal2Cancel}
                  style={{ width: 120, height: 40, marginRight: 16 }}
                  ghost
                  type='primary'
                >
                  取消
                </Button>
                <Button
                  onClick={handleModal2OK}
                  style={{ width: 120, height: 40 }}
                  type='primary'
                  loading={loading}
                >
                  确认
                </Button>
              </div>
            </div>
          </Modal>
        </div>

        <img
          src={image}
          alt='文章配图'
          style={{ width: '313px', height: '160px', alignSelf: 'flex-start' }}
        ></img>
      </div>

      {line && (
        <>
          <Grow y='40px' />{' '}
          <LineSVG width='1120' height='1' color='rgba(0,0,0,0.06)' />
          <Grow y='40px' />{' '}
        </>
      )}
    </div>
  );
};
