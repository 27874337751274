import React from 'react';
import { Carousel } from 'antd';
import BannerClicker from './BannerClicker';

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '80px',
        background: 'rgba(255, 255, 255, 0.4)',
        cursor: 'pointer',
        zIndex: '999',
      }}
      onClick={onClick}
    >
      <svg
        t='1601010842273'
        viewBox='0 0 1024 1024'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        p-id='5359'
        width='32'
        height='32'
        style={{
          opacity: '0.2',
        }}
      >
        <path
          d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8c-16.4 12.8-16.4 37.5 0 50.3l450.8 352.1c5.3 4.1 12.9 0.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z'
          p-id='5360'
        ></path>
      </svg>
    </div>
  );
}
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        right: '0',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '80px',
        background: 'rgba(255, 255, 255, 0.4)',
        cursor: 'pointer',
        zIndex: '999',
      }}
      onClick={onClick}
    >
      <svg
        t='1601010809670'
        viewBox='0 0 1024 1024'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        p-id='5061'
        width='32'
        height='32'
        style={{
          opacity: '0.2',
        }}
      >
        <path
          d='M765.7 486.8L314.9 134.7c-5.3-4.1-12.9-0.4-12.9 6.3v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1c16.4-12.8 16.4-37.6 0-50.4z'
          p-id='5062'
        ></path>
      </svg>
    </div>
  );
}

const config = {
  arrows: true,
  dots: false,
  infinite: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
const model = [
  {
    // url: `${window.location.origin}/pc/goods/detail/ff8080815870065301588a83dc8700bc`,
    url:
      'https://vem-mobile.nfsq.com.cn/zmall-mobile/auth?redirect=goods-detail&commodityId=20200302000016431&commodityType=1',
    // qr: false,
    qr: true,
  },
  {
    url:
      'https://vem-mobile.nfsq.com.cn/zmall-mobile/auth?redirect=goods-detail&commodityId=20200413000000021&commodityType=1',
    qr: true,
  },
  {
    url:
      'https://vem-mobile.nfsq.com.cn/zmall-mobile/auth?redirect=goods-detail&commodityId=20200426000000031&commodityType=1',
    qr: true,
  },
];
export default () => {
  return (
    <div style={{ width: '1200px', height: '450px' }}>
      <Carousel {...config}>
        <div>
          <div style={{ position: 'relative' }}>
            <img
              src={require('../../assets/banner1-药业.png')}
              style={{ width: '1200px' }}
              alt=''
            ></img>
            <BannerClicker {...model[0]} />
          </div>
        </div>
        <div>
          <div style={{ position: 'relative' }}>
            <img
              src={require('../../assets/banner2-化妆品.png')}
              style={{ width: '1200px' }}
              alt=''
            ></img>
            <BannerClicker {...model[1]} />
          </div>
        </div>
        <div>
          <div style={{ position: 'relative' }}>
            <img
              src={require('../../assets/banner3-农夫山泉.png')}
              style={{ width: '1200px' }}
              alt=''
            ></img>
            <BannerClicker {...model[2]} />
          </div>
        </div>
      </Carousel>
    </div>
  );
};
